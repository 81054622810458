import React, {useState, useEffect, useRef} from 'react'
import Jumbotron from '../Jumbotron/Jumbotron';
import classes from './Landing.module.css';
import LogoLoader from '../UI/LogoLoader/LogoLoader';
import AboutBG from '../../assets/images/background/about-popup-bg.png'
import KMTLogo from '../../assets/images/logo/kaufmeinticket_logo.png'
import Cross from '../../assets/images/icons/cross.png'
import useOutsideAlerter from '../UseOutsideAlerter/UseOutsideAlerter';

import UserCircle from '../../assets/images/icons/usercircle.svg'
import Calendar from '../../assets/images/icons/calendar.svg'
import Money from '../../assets/images/icons/money.svg'
import MoneyHand from '../../assets/images/icons/moneyhand.svg'
import Pencil from '../../assets/images/icons/pencil.svg'
import Pop from '../../assets/images/icons/pop.svg'
import UserCheck from '../../assets/images/icons/usercheck.svg'
import TicketVector from '../../assets/images/icons/ticket_vector.svg'
import Thumbsup from '../../assets/images/icons/thumbsup.svg'
import InfoIcon from '../../assets/images/icons/info.svg'

import Search from '../Search/Search';

import Topline from '../../assets/images/icons/topline.svg'
import ToplineStraight from '../../assets/images/icons/topline_straight.svg'

import { SELL } from '../../URLS';
import { useTranslation } from 'react-i18next';

export default function Landing() {

    const { t, i18n } = useTranslation();
    const [showLoader, setShowLoader] = useState(localStorage.getItem("loading")==="false" ? false : true)
    const [showAboutPopup, setShowAboutPopup] = useState(false)
    const [buttonSelected, setButtonSelected] = useState("ST")
    const [highlightSearchBar, setHighlightSearchBar] = useState(false)
    const [windowWidth, setWindowWidth] = useState(0);

    useEffect(() => {
        if(localStorage.getItem("loading")==="false"){
            //NOTHING TO DO
        }
        else{
            setTimeout(function(){
                localStorage.setItem("loading", false)
                setShowLoader(false)
            },2000);
        }

        setWindowWidth(window.innerWidth);

        if(window.innerWidth < 1025){

            const button = document.getElementById('sticky-button');
            const buttonRect = button.getBoundingClientRect();
            const buttonBottom = buttonRect.bottom;
            const viewportHeight = window.innerHeight;

            if (window.scrollY > 300 && window.scrollY < buttonBottom) {
                if (!button.classList.contains(classes.StickyButton)) {
                    button.classList.add(classes.StickyButton);
                }
            } else {
                if (button.classList.contains(classes.StickyButton)) {
                    button.classList.remove(classes.StickyButton);
                }
            }

            window.addEventListener('scroll', function() {
                // console.log(window.scrollY, buttonBottom)
                if (window.scrollY > 300 && window.scrollY < buttonBottom) {
                    if (!button.classList.contains(classes.StickyButton)) {
                        button.classList.add(classes.StickyButton);
                    }
                } else {
                    if (button.classList.contains(classes.StickyButton)) {
                        button.classList.remove(classes.StickyButton);
                    }
                }
            });
        }
    },[])

    var editRef = useRef(null);

    const setEditF = (value) => {
        if (value) {
        }
        else {
            setTimeout(() => {
                setShowAboutPopup(false)
            }, 200)
        }
    }
    useOutsideAlerter(editRef, setEditF, false);

    const SellTicketsSteps = [
        {
            headIcon: UserCircle,
            title: 'sell_tickets_process_1_title',
            content: 'sell_tickets_process_1_content'
        },
        {
            headIcon: Calendar,
            title: 'sell_tickets_process_2_title',
            content: 'sell_tickets_process_2_content'
        },
        {
            headIcon: Pencil,
            title: 'sell_tickets_process_3_title',
            content: 'sell_tickets_process_3_content'
        },
        {
            headIcon: MoneyHand,
            title: 'sell_tickets_process_4_title',
            content: 'sell_tickets_process_4_content'
        },
        {
            headIcon: UserCheck,
            title: 'sell_tickets_process_5_title',
            content: 'sell_tickets_process_5_content'
        },
        {
            headIcon: Money,
            title: 'sell_tickets_process_6_title',
            content: 'sell_tickets_process_6_content'
        }
    ]
    const BuyTicketsSteps = [
        {
            headIcon: UserCircle,
            title: 'buy_tickets_process_1_title',
            content: 'buy_tickets_process_1_content'
        },
        {
            headIcon: Calendar,
            title: 'buy_tickets_process_2_title',
            content: 'buy_tickets_process_2_content'
        },
        {
            headIcon: UserCheck,
            title: 'buy_tickets_process_3_title',
            content: 'buy_tickets_process_3_content'
        },
        {
            headIcon: TicketVector,
            title: 'buy_tickets_process_4_title',
            content: 'buy_tickets_process_4_content'
        },
        {
            headIcon: Pop,
            title: 'buy_tickets_process_5_title',
            content: 'buy_tickets_process_5_content'
        },
        {
            headIcon: Thumbsup,
            title: 'buy_tickets_process_6_title',
            content: 'buy_tickets_process_6_content'
        }
    ]

    const [selectedSteps, setSelectedSteps] = useState(SellTicketsSteps);

    const highlightSearch = (highlight) => {
        if(highlight){
            window.scrollTo({ top: 0, behavior: 'smooth' })
            setTimeout(() => {
                document.body.style.overflow = 'hidden';
                setHighlightSearchBar(true)
            },700)
        }else{
            document.body.style.overflow = 'unset';
            setHighlightSearchBar(false)
            setTimeout(() => {
                const inputElement = document.querySelector('#home-search-id');
                inputElement.focus();
            },500)
        }
    }


    return (
        <div>
            <Jumbotron />
            {
                showAboutPopup ?
                    <div className={classes.AboutPopup}>
                        <div className={classes.AboutPopupContainer}>
                            <div className={classes.AboutPopupBlock} ref={editRef}>
                                <div>
                                    <div className={classes.Close} onClick={()=>setShowAboutPopup(false)}>
                                        <img src={Cross} alt="" />
                                    </div>
                                    <div className={classes.AboutImageSection}>
                                        <div className={classes.Logo}>
                                            <img src={KMTLogo} alt="KMTLogo" />
                                        </div>
                                        <div className={classes.BG}>
                                            <img src={AboutBG} alt="kmt about" />
                                        </div>
                                    </div>
                                    <div className={classes.AboutTextSection}>
                                        <div>{t('About_Us')}</div>
                                        <div>
                                            {
                                                t('about_popup_content').split('\n').map(function(line){
                                                    return <>{line}<br /></>
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                : null
            }
            {
                highlightSearchBar ?
                    <div className={classes.HighlightContainer} onClick={() => highlightSearch(false)}>
                        <div className={classes.Highlight}>
                            <Search jumbotron={true} highlighted={true} />
                        </div>
                    </div>
                : null
            }
            <div className={classes.BuySellProcess}>
                <div className={classes.BuySellProcessContainer}>
                    <div className={classes.Header}>
                        <div className={classes.ButtonCoverMobile}>
                            <div>{t('How_to')}</div>
                        </div>
                        <div className={classes.ButtonContainer}>
                            <div
                                className={`${classes.Button} ${buttonSelected === "ST" ? classes.Selected : ""}`}
                                onClick={() => { setButtonSelected("ST"); setSelectedSteps(SellTicketsSteps); }}
                            >
                            { windowWidth > 1024 ? t('sell_your_tickets') : t('sell')}
                            </div>
                            <div
                                className={`${classes.Button} ${buttonSelected === "BT" ? classes.Selected : ""}`}
                                onClick={() => { setButtonSelected("BT"); setSelectedSteps(BuyTicketsSteps); }}
                            >
                            { windowWidth > 1024 ? t('buy_tickets') : t('Buy')}
                            </div>
                            <div
                                className={classes.Slider}
                                style={{
                                  transform: buttonSelected === "ST" ? "translateX(0%)" : "translateX(100%)",
                                }}
                            >
                            </div>   
                        </div>
                        <div className={classes.About} onClick={() => setShowAboutPopup(true)}>
                            {t('About_Us')}
                            <img className={classes.AboutInfoImage} src={InfoIcon} alt="" />
                        </div>
                    </div>
                    {/*<div className={classes.SelectedTicketsTitle}>
                        {
                            buttonSelected === "ST" ?
                                <>{t('sell_your_tickets')}</>
                            : <>{t('buy_tickets')}</>
                        }
                    </div>*/}
                    <div className={classes.BodyContainer}>
                        <div className={classes.BackgroundLines}>
                            <img src={Topline} alt="" />
                            <img src={Topline} alt="" />
                            <img src={Topline} alt="" />
                            <img src={Topline} alt="" />
                            <img src={Topline} alt="" />
                            <img src={ToplineStraight} alt="" />
                            <img src={ToplineStraight} alt="" />
                        </div>
                        <div className={classes.StepsContainer}>
                            {
                                selectedSteps.map(function(item, index){
                                    return <div className={classes.Step}>
                                        <div className={classes.HeadIcon}><img src={item.headIcon} alt="" /></div>
                                        <div className={classes.StepContent}>
                                            <div className={classes.Title}><span>{index+1}</span> {t(item.title)}</div>
                                            <div className={classes.Content}>{t(item.content).split('\n').map(function(line){
                                                return <div className={classes.BulletPoints}>{line}</div>
                                            })}</div>
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                    </div>
                    <div className={classes.BottomButtonContainer} id="sticky-button">
                        {
                            buttonSelected === "ST" ?
                                <a href={SELL}>
                                    <div className={classes.SellTicketsButton} onClick={() => sessionStorage.setItem("highlightSearchBar", true)}>
                                        {t('sell_tickets_now')}
                                    </div>
                                </a>
                            : <div className={classes.BuyTicketsButton} onClick={() => highlightSearch(true)}>
                                {t('search_artist_event')}
                            </div>
                        }
                    </div>
                </div> 
            </div>

            <div className={classes.InstagramPosts}>
                <iframe src="https://cdn.lightwidget.com/widgets/d63f77903b7257d69a5d3af80d61f041.html" scrolling="no" allowtransparency="true" className="lightwidget-widget" style={{width:"100%", border:"0", overflow:"hidden", color: "white"}}></iframe>
            </div>
            { showLoader ? <LogoLoader /> : null }
        </div>
    )
}
import React, { useState, useEffect } from 'react';
import Search from '../Search/Search';
import classes from './Jumbotron.module.css';
import logo from '../../assets/images/logo/kaufmeinticket_logo.png';
import TicketImg from '../../assets/images/background/ticket_home.png';
import AdminTicket from '../../assets/images/icons/admin-ticket.svg'
//import downArrow from '../../assets/images/icons/down_arrow.png';
import axiosInstance, { config } from '../../axiosBase';
import { EVENT_STRING } from '../../URLS';
import { useTranslation } from 'react-i18next';

const Jumbotron = () => {

	const { t, i18n } = useTranslation();
	const [simpleSearch, setSimpleSearch] = useState(true);	// For toggling between simple and advanced search
	// Advanced search will be removed once finalized


	// Toggle between simple and advanced search
	const searchTypeToggle = () => {
		setSimpleSearch(!simpleSearch);
	}
	//const scrollNextRef = useRef();

	/*const smoothScroll = (h) => {
		let i = h || 0;
		if (i < scrollNextRef.current.offsetTop) {
			setTimeout(() => {
				window.scrollTo(0, i);
				smoothScroll(i + 10);
			}, 10);
		}
	}*/
	const [artists, setArtists] = useState([
	    {
	        "name": "Iron Maiden",
	        "link": "iron-maiden-karten"
	    },
	    {
	        "name": "Peter Maffay",
	        "link": "peter-maffay-karten"
	    },
	    {
	        "name": "Kontra K",
	        "link": "kontra-k-karten"
	    },
	    {
	        "name": "Sting",
	        "link": "sting-karten"
	    },
	    {
	        "name": "Billie Eilish",
	        "link": "billie-eilish-karten"
	    },
	    {
	        "name": "SDP",
	        "link": "sdp-karten"
	    },
	    {
	        "name": "Sascha Grammel",
	        "link": "sascha-grammel-karten"
	    },
	    {
	        "name": "Apache 207",
	        "link": "apache-207-karten"
	    },
	    {
	        "name": "Jason Mraz",
	        "link": "jason-mraz-karten"
	    },
	    {
	        "name": "Imagine Dragons",
	        "link": "imagine-dragons-karten"
	    }
	])


	useEffect(() => {
        axiosInstance.get('/searchevents?a=home&q=home', config)
        .then(function(data){
            let listOfArtists = []
        	data.data.categories.map(function(item){
        		listOfArtists.push({
        			"name": item.name,
        			"link": item.link_rewrite
        		})
        	})
        	setArtists(listOfArtists)
        }).catch(function(err){
        	console.log(err, "ERROR")
        })
    },[])



	return (
		<div className={classes.Jumbotron}>
			<div className={classes.Jumbotron2}>
				<div className={classes.TicketImage}>
					<img className={""} src={TicketImg} alt="" />
				</div>
				<div className={classes.Jumbotron3}>
					<img className={classes.Logo} src={logo} alt="" />
					<div className={classes.FlipContent}>
						<div>
							<ul className={classes.flip2}>
								<li>{t('fair_secure_transparent')}</li>
								<li>{t('fan_to_fan_ticket')}</li>
								<li>{t('fair_secure_transparent')}</li>
								<li>{t('fan_to_fan_ticket')}</li>
							</ul>
						</div>
					</div>
					<Search jumbotron={true} />
				</div>
			</div>
			<div className={classes.ArtistBackground}>
			<div className={classes.ArtistBanner}>
				{
					artists.map(function(item){
						return <div className={[classes.ArtistContainer, classes.FirstIter].join(' ')}>
							<div className={classes.Artist}>
								<a href={EVENT_STRING+item.link}>{item.name}</a>
								{/*<img src={AdminTicket} alt="" />*/}
							</div>
						</div>
					})
				}
				{
					artists.map(function(item){
						return <div className={[classes.ArtistContainer, classes.SecondIter].join(' ')}>
							<div className={classes.Artist}>
								<a href={EVENT_STRING+item.link}>{item.name}</a>
								{/*<img src={AdminTicket} alt="" />*/}
							</div>
						</div>
					})
				}
			</div>
			</div>
		</div>
	);
}

export default Jumbotron;
import consoleLog from '../../../log';

const getLanguage = () => {
    const lang = localStorage.getItem("i18nextLng")
    if(lang == "en"){
        return 7
    }else if(lang == "de"){
        return 4
    }
}

function escapeHtml(unsafe) {
  return unsafe.replace(/[&<>"']/g, function(match) {
    return {
      '&': '&amp;',
      '<': '&lt;',
      '>': '&gt;',
      '"': '&quot;',
      "'": '&#39;'
    }[match];
  });
}


export const AddressesPutData = (userData, addressData) => {
    consoleLog(userData, addressData)
    return (
`<?xml version="1.0" encoding="UTF-8"?>
<prestashop xmlns:xlink="http://www.w3.org/1999/xlink">
<address>
<id>`+userData.data.addresses[0].id+`</id>
<id_lang>`+getLanguage()+`</id_lang>
<id_customer>`+userData.data.addresses[0].id_customer+`</id_customer>
<id_manufacturer>`+userData.data.addresses[0].id_manufacturer+`</id_manufacturer>
<id_supplier>`+userData.data.addresses[0].id_supplier+`</id_supplier>
<id_warehouse>`+userData.data.addresses[0].id_warehouse+`</id_warehouse>
<id_country>`+addressData[9].country+`</id_country>
<id_state>`+userData.data.addresses[0].id_state+`</id_state>
<alias>`+userData.data.addresses[0].alias+`</alias>
<company>`+addressData[2].value+`</company>
<lastname>`+addressData[1].value+`</lastname>
<firstname>`+addressData[0].value+`</firstname>
<vat_number></vat_number>
<address1>`+addressData[3].value+`</address1>
<address2>`+addressData[4].value+`</address2>
<address3>`+addressData[5].value+`</address3>
<postcode>`+addressData[6].value+`</postcode>
<city>`+addressData[7].value+`</city>
<other></other>
<phone></phone>
<phone_mobile>`+addressData[8].value+`</phone_mobile>
<dni></dni>
<deleted>`+userData.data.addresses[0].deleted+`</deleted>
<date_add>`+userData.data.addresses[0].date_add+`</date_add>
<date_upd>`+userData.data.addresses[0].date_upd+`</date_upd>
<associations>
    <countries nodeType="countries" api="countries">
        <countries>
            <id>`+userData.data.addresses[0].associations.countries[0].id+`</id>
            <country_uk>`+userData.data.addresses[0].associations.countries[0].country_uk+`</country_uk>
            <country_de>`+userData.data.addresses[0].associations.countries[0].country_de+`</country_de>
        </countries>
    </countries>
</associations>
</address>
</prestashop>`
    );
}


export const PhoneNumberData = (userData, phonenumber) => {

    return (
`<?xml version="1.0" encoding="UTF-8"?>
<prestashop xmlns:xlink="http://www.w3.org/1999/xlink">
<address>
<id>`+userData.data.addresses[0].id+`</id>
<id_lang>`+getLanguage()+`</id_lang>
<id_customer>`+userData.data.addresses[0].id_customer+`</id_customer>
<id_manufacturer>`+userData.data.addresses[0].id_manufacturer+`</id_manufacturer>
<id_supplier>`+userData.data.addresses[0].id_supplier+`</id_supplier>
<id_warehouse>`+userData.data.addresses[0].id_warehouse+`</id_warehouse>
<id_country>`+userData.data.addresses[0].id_country+`</id_country>
<id_state>`+userData.data.addresses[0].id_state+`</id_state>
<alias>`+userData.data.addresses[0].alias+`</alias>
<company>`+userData.data.addresses[0].company+`</company>
<lastname>`+userData.data.addresses[0].lastname+`</lastname>
<firstname>`+userData.data.addresses[0].firstname+`</firstname>
<vat_number></vat_number>
<address1>`+userData.data.addresses[0].address1+`</address1>
<address2>`+userData.data.addresses[0].address2+`</address2>
<address3>`+userData.data.addresses[0].address3+`</address3>
<postcode>`+userData.data.addresses[0].postcode+`</postcode>
<city>`+userData.data.addresses[0].city+`</city>
<other></other>
<phone></phone>
<phone_mobile>`+phonenumber+`</phone_mobile>
<dni></dni>
<deleted>`+userData.data.addresses[0].deleted+`</deleted>
<date_add>`+userData.data.addresses[0].date_add+`</date_add>
<date_upd>`+userData.data.addresses[0].date_upd+`</date_upd>
<associations>
    <countries nodeType="countries" api="countries">
        <countries>
            <id>`+userData.data.addresses[0].associations.countries[0].id+`</id>
            <country_uk>`+userData.data.addresses[0].associations.countries[0].country_uk+`</country_uk>
            <country_de>`+userData.data.addresses[0].associations.countries[0].country_de+`</country_de>
        </countries>
    </countries>
</associations>
</address>
</prestashop>`
    );
}


export const AddressesPostData = (id_customer, addressData) => {
    consoleLog(addressData)
    return (
`<?xml version="1.0" encoding="UTF-8"?>
<prestashop xmlns:xlink="http://www.w3.org/1999/xlink">
<address>
<id></id>
<id_lang>`+getLanguage()+`</id_lang>
<id_customer>`+id_customer+`</id_customer>
<id_manufacturer></id_manufacturer>
<id_supplier>0</id_supplier>
<id_warehouse></id_warehouse>
<id_country>`+addressData[9].country+`</id_country>
<id_state></id_state>
<alias>`+addressData[0].value+`</alias>
<company>`+addressData[2].value+`</company>
<lastname>`+addressData[1].value+`</lastname>
<firstname>`+addressData[0].value+`</firstname>
<vat_number></vat_number>
<address1>`+addressData[3].value+`</address1>
<address2>`+addressData[4].value+`</address2>
<address3>`+addressData[5].value+`</address3>
<postcode>`+addressData[6].value+`</postcode>
<city>`+addressData[7].value+`</city>
<other></other>
<phone></phone>
<phone_mobile>`+addressData[8].value+`</phone_mobile>
<dni></dni>
<deleted></deleted>
<date_add></date_add>
<date_upd></date_upd>
<associations>
    <countries nodeType="countries" api="countries">
        <countries>
            <id></id>
            <country_uk></country_uk>
            <country_de></country_de>
        </countries>
    </countries>
</associations>
</address>
</prestashop>`
    );
}


export const CustomersData = (preData, customerData) => {
    consoleLog(customerData, preData)
    return (
`<?xml version="1.0" encoding="UTF-8"?>
<prestashop xmlns:xlink="http://www.w3.org/1999/xlink">
<customer>
<id>`+preData.data.customer.id+`</id>
<id_default_group>`+preData.data.customer.id_default_group+`</id_default_group>
<id_lang>`+getLanguage()+`</id_lang>
<newsletter_date_add>`+preData.data.customer.newsletter_date_add+`</newsletter_date_add>
<ip_registration_newsletter>`+preData.data.customer.ip_registration_newsletter+`</ip_registration_newsletter>
<last_passwd_gen>`+preData.data.customer.last_passwd_gen+`</last_passwd_gen>
<secure_key></secure_key>
<deleted>`+preData.data.customer.deleted+`</deleted>
<official_email>`+customerData[2].value+`</official_email>
<paypal_verified></paypal_verified>
<lastname>`+customerData[1].value+`</lastname>
<firstname>`+customerData[0].value+`</firstname>
<email>`+preData.data.customer.email+`</email>
<id_gender>`+preData.data.customer.id_gender+`</id_gender>
<birthday>`+preData.data.customer.birthday+`</birthday>
<newsletter>`+preData.data.customer.newsletter+`</newsletter>
<optin>`+preData.data.customer.optin+`</optin>
<website></website>
<company></company>
<siret></siret>
<ape></ape>
<outstanding_allow_amount>`+preData.data.customer.outstanding_allow_amount+`</outstanding_allow_amount>
<id_risk>`+preData.data.customer.id_risk+`</id_risk>
<max_payment_days>`+preData.data.customer.max_payment_days+`</max_payment_days>
<active>`+preData.data.customer.active+`</active>
<note></note>
<is_guest>`+preData.data.customer.is_guest+`</is_guest>
<id_shop>`+preData.data.customer.id_shop+`</id_shop>
<id_shop_group>`+preData.data.customer.id_shop_group+`</id_shop_group>
<date_add>`+preData.data.customer.date_add+`</date_add>
<date_upd>`+preData.data.customer.date_upd+`</date_upd>
<reset_password_token></reset_password_token>
<reset_password_validity>`+preData.data.customer.reset_password_validity+`</reset_password_validity>
<paymentmethod></paymentmethod>
<iban></iban>
<email_language></email_language>
<paypal_email></paypal_email>
<paypal_verified></paypal_verified>
<associations>
    <groups>
        <group>
            <id></id>
        </group>
    </groups>
</associations>
</customer>
</prestashop>`
    );
}


export const PaymentData = (preData, customerData, paymentInfo) => {
    consoleLog(customerData, preData)
    return (
`<?xml version="1.0" encoding="UTF-8"?>
<prestashop xmlns:xlink="http://www.w3.org/1999/xlink">
<customer>
<id>`+preData.data.customer.id+`</id>
<id_default_group>`+preData.data.customer.id_default_group+`</id_default_group>
<id_lang>`+getLanguage()+`</id_lang>
<newsletter_date_add>`+preData.data.customer.newsletter_date_add+`</newsletter_date_add>
<ip_registration_newsletter>`+preData.data.customer.ip_registration_newsletter+`</ip_registration_newsletter>
<last_passwd_gen>`+preData.data.customer.last_passwd_gen+`</last_passwd_gen>
<secure_key></secure_key>
<deleted>`+preData.data.customer.deleted+`</deleted>
<official_email>`+preData.data.customer.official_email+`</official_email>
<lastname>`+preData.data.customer.lastname+`</lastname>
<firstname>`+preData.data.customer.firstname+`</firstname>
<email>`+preData.data.customer.email+`</email>
<id_gender>`+preData.data.customer.id_gender+`</id_gender>
<birthday>`+preData.data.customer.birthday+`</birthday>
<newsletter>`+preData.data.customer.newsletter+`</newsletter>
<optin>`+preData.data.customer.optin+`</optin>
<website></website>
<company></company>
<siret></siret>
<ape></ape>
<outstanding_allow_amount>`+preData.data.customer.outstanding_allow_amount+`</outstanding_allow_amount>
<id_risk>`+preData.data.customer.id_risk+`</id_risk>
<max_payment_days>`+preData.data.customer.max_payment_days+`</max_payment_days>
<active>`+preData.data.customer.active+`</active>
<note></note>
<is_guest>`+preData.data.customer.is_guest+`</is_guest>
<id_shop>`+preData.data.customer.id_shop+`</id_shop>
<id_shop_group>`+preData.data.customer.id_shop_group+`</id_shop_group>
<date_add>`+preData.data.customer.date_add+`</date_add>
<date_upd>`+preData.data.customer.date_upd+`</date_upd>
<reset_password_token></reset_password_token>
<reset_password_validity>`+preData.data.customer.reset_password_validity+`</reset_password_validity>
<paymentmethod>`+paymentInfo.method+`</paymentmethod>
<iban>`+paymentInfo.IBAN_num+`</iban>
<email_language></email_language>
<paypal_email>`+paymentInfo.PAYPAL_email+`</paypal_email>
<paypal_verified>`+paymentInfo.PAYPAL_verified+`</paypal_verified>
<associations>
    <groups>
        <group>
            <id></id>
        </group>
    </groups>
</associations>
</customer>
</prestashop>`
    );
}


export const RegistrationData = (customerData) => {
    consoleLog(customerData)
    return (
`<?xml version="1.0" encoding="UTF-8"?>
<prestashop xmlns:xlink="http://www.w3.org/1999/xlink">
<customer>
<id></id>
<id_default_group></id_default_group>
<id_lang>`+getLanguage()+`</id_lang>
<newsletter_date_add></newsletter_date_add>
<ip_registration_newsletter></ip_registration_newsletter>
<last_passwd_gen></last_passwd_gen>
<secure_key></secure_key>
<deleted></deleted>
<passwd>`+escapeHtml(customerData[3].value)+`</passwd>
<official_email>`+customerData[2].value+`</official_email>
<paypal_verified></paypal_verified>
<lastname>`+customerData[1].value+`</lastname>
<firstname>`+customerData[0].value+`</firstname>
<email>`+customerData[2].value+`</email>
<id_gender></id_gender>
<birthday></birthday>
<newsletter></newsletter>
<optin></optin>
<website></website>
<company></company>
<siret></siret>
<ape></ape>
<outstanding_allow_amount></outstanding_allow_amount>
<id_risk></id_risk>
<max_payment_days></max_payment_days>
<active>1</active>
<note></note>
<is_guest></is_guest>
<id_shop></id_shop>
<id_shop_group></id_shop_group>
<date_add></date_add>
<date_upd></date_upd>
<reset_password_token></reset_password_token>
<reset_password_validity></reset_password_validity>
<associations>
    <groups>
        <group>
            <id></id>
        </group>
    </groups>
</associations>
</customer>
</prestashop>`
    );
}


export const ChangePasswordData = (preData, customerData) => {
    consoleLog(customerData, preData)
    return (
`<?xml version="1.0" encoding="UTF-8"?>
<prestashop xmlns:xlink="http://www.w3.org/1999/xlink">
<customer>
<id>`+preData.data.customer.id+`</id>
<id_default_group>`+preData.data.customer.id_default_group+`</id_default_group>
<id_lang>`+getLanguage()+`</id_lang>
<newsletter_date_add>`+preData.data.customer.newsletter_date_add+`</newsletter_date_add>
<ip_registration_newsletter>`+preData.data.customer.ip_registration_newsletter+`</ip_registration_newsletter>
<last_passwd_gen>`+preData.data.customer.last_passwd_gen+`</last_passwd_gen>
<secure_key></secure_key>
<old_passwd>`+customerData.old_password+`</old_passwd>
<passwd>`+escapeHtml(customerData.new_password)+`</passwd>
<deleted>`+preData.data.customer.deleted+`</deleted>
<official_email>`+preData.data.customer.official_email+`</official_email>
<paypal_verified></paypal_verified>
<lastname>`+preData.data.customer.lastname+`</lastname>
<firstname>`+preData.data.customer.firstname+`</firstname>
<email>`+preData.data.customer.email+`</email>
<id_gender>`+preData.data.customer.id_gender+`</id_gender>
<birthday>`+preData.data.customer.birthday+`</birthday>
<newsletter>`+preData.data.customer.newsletter+`</newsletter>
<optin>`+preData.data.customer.optin+`</optin>
<website></website>
<company></company>
<siret></siret>
<ape></ape>
<outstanding_allow_amount>`+preData.data.customer.outstanding_allow_amount+`</outstanding_allow_amount>
<id_risk>`+preData.data.customer.id_risk+`</id_risk>
<max_payment_days>`+preData.data.customer.max_payment_days+`</max_payment_days>
<active>`+preData.data.customer.active+`</active>
<note></note>
<is_guest>`+preData.data.customer.is_guest+`</is_guest>
<id_shop>`+preData.data.customer.id_shop+`</id_shop>
<id_shop_group>`+preData.data.customer.id_shop_group+`</id_shop_group>
<date_add>`+preData.data.customer.date_add+`</date_add>
<date_upd>`+preData.data.customer.date_upd+`</date_upd>
<reset_password_token></reset_password_token>
<reset_password_validity>`+preData.data.customer.reset_password_validity+`</reset_password_validity>
<associations>
    <groups>
        <group>
            <id></id>
        </group>
    </groups>
</associations>
</customer>
</prestashop>`
    );
}


export const cartsData = (info, id='', updated_time='', shipping_id_carrier='0') => {
    return (
`<?xml version="1.0" encoding="UTF-8"?>
<prestashop xmlns:xlink="http://www.w3.org/1999/xlink">
<cart>
<id>`+id+`</id>
<id_address_delivery>`+info.address_id+`</id_address_delivery>
<id_address_invoice>`+info.address_id+`</id_address_invoice>
<id_currency >1</id_currency>
<id_customer>`+info.customer_id+`</id_customer>
<id_guest></id_guest>
<id_lang>`+getLanguage()+`</id_lang>
<id_shop_group>1</id_shop_group>
<id_shop>1</id_shop>
<id_carrier>`+shipping_id_carrier+`</id_carrier>
<recyclable></recyclable>
<gift></gift>
<gift_message></gift_message>
<mobile_theme></mobile_theme>
<delivery_option></delivery_option>
<secure_key></secure_key>
<allow_seperated_package></allow_seperated_package>
<date_add></date_add>
<date_upd></date_upd>
<associations>
<cart_rows nodeType="cart_row" virtualEntity="true">
<cart_row>
<id_product>`+info.product_id+`</id_product>
<id_product_attribute>`+info.combination_id+`</id_product_attribute>
<id_address_delivery>0</id_address_delivery>
<id_customization>0</id_customization>
<quantity>`+info.quantity+`</quantity>
</cart_row>
</cart_rows>
</associations>
</cart>
</prestashop>`
    );
}

export const CreateOrdersData = (mode, customer, event, ticket, cart_id, address_id, shipping_method, price) => {
    let mod = "";
    let payment = "";

    if(mode == "stripe" || mode == "sofort"){
        mod = "stripejs";
        payment = "Stripe Payment Pro";
    }else if(mode == "paypal"){
        mod = "paypal";
        payment = "Paypal";
    }

    const shipping_id_carriers = {
        "Germany": "26",
        "GermanyDHL": "30",
        "PickUpVenue": "32",
        "All": "29",
        "ETicket": "21"
    }
    let shipping_id_carrier = shipping_id_carriers["ETicket"];

    if(ticket.ticketType == "hard-ticket"){
        if(event.location_country == "1"){
            shipping_id_carrier = shipping_id_carriers["Germany"];
        }else{
            shipping_id_carrier = shipping_id_carriers["All"];
        }
    }
    if(shipping_method == "2"){
        shipping_id_carrier = shipping_id_carriers["GermanyDHL"];
    }else if(shipping_method == "3"){
        shipping_id_carrier = shipping_id_carriers["PickUpVenue"];
    }

    let ticketmaster_ticket = false;

    if(customer.ticketmaster_details && ticket.allDetails.type == "5"){
        if(Object.keys(customer.ticketmaster_details).length){
            console.log(customer.ticketmaster_details, customer.ticketmaster_details.email)
            ticketmaster_ticket = true;
        }
    }

    return (
`<?xml version="1.0" encoding="UTF-8"?>
<prestashop xmlns:xlink="http://www.w3.org/1999/xlink">
<order>
<id_address_delivery xlink:href="https://www.kmttest.de/api/addresses/9391">`+address_id+`</id_address_delivery>
<id_address_invoice xlink:href="https://www.kmttest.de/api/addresses/9391">`+address_id+`</id_address_invoice>
<id_cart xlink:href="https://www.kmttest.de/api/carts/3554">`+cart_id+`</id_cart>
<id_currency xlink:href="https://www.kmttest.de/api/currencies/1">1</id_currency>
<id_lang>`+getLanguage()+`</id_lang>
<id_customer xlink:href="https://www.kmttest.de/api/customers/761">`+customer.id+`</id_customer>
<id_customer_seller xlink:href="https://www.kmttest.de/api/customers/761">`+ticket.allDetails.id_customer+`</id_customer_seller>
<id_carrier>`+shipping_id_carrier+`</id_carrier>
<current_state xlink:href="https://www.kmttest.de/api/order_states/2">2</current_state>
<module>`+mod+`</module>
<invoice_number>0</invoice_number>
<invoice_date>0000-00-00 00:00:00</invoice_date>
<delivery_number>0</delivery_number>
<delivery_date>0000-00-00 00:00:00</delivery_date>
<valid>0</valid>
<date_add>2021-07-28 14:36:54</date_add>
<date_upd>2021-07-28 14:39:57</date_upd>
<shipping_number notFilterable="true"></shipping_number>
<shipping_method>`+ (ticket.allDetails.type == "1" ? "" : shipping_method) +`</shipping_method>
<id_shop_group></id_shop_group>
<id_shop></id_shop>
<secure_key></secure_key>
<payment>`+payment+`</payment>
<recyclable></recyclable>
<gift></gift>
<gift_message></gift_message>
<mobile_theme></mobile_theme>
<total_discounts>0.000000</total_discounts>
<total_discounts_tax_incl>0.000000</total_discounts_tax_incl>
<total_discounts_tax_excl>0.000000</total_discounts_tax_excl>
<total_paid>`+parseFloat(price).toFixed(6)+`</total_paid>
<total_paid_tax_incl>`+parseFloat(price).toFixed(6)+`</total_paid_tax_incl>
<total_paid_tax_excl>`+parseFloat(price).toFixed(6)+`</total_paid_tax_excl>
<total_paid_real>0.000000</total_paid_real>
<total_products>`+parseFloat(price).toFixed(6)+`</total_products>
<total_products_wt>`+parseFloat(price).toFixed(6)+`</total_products_wt>
<total_shipping>0.000000</total_shipping>
<total_shipping_tax_incl>0.000000</total_shipping_tax_incl>
<total_shipping_tax_excl>0.000000</total_shipping_tax_excl>
<carrier_tax_rate>0.000</carrier_tax_rate>
<total_wrapping>0.000000</total_wrapping>
<total_wrapping_tax_incl>0.000000</total_wrapping_tax_incl>
<total_wrapping_tax_excl>0.000000</total_wrapping_tax_excl>
<round_mode></round_mode>
<round_type></round_type>
<conversion_rate>1.000000</conversion_rate>
<reference></reference>
<ticketmaster_account_email>`+(ticketmaster_ticket ? customer.ticketmaster_details.tm_email : "")+`</ticketmaster_account_email>
<ticketmaster_account_firstname>`+(ticketmaster_ticket ? customer.ticketmaster_details.tm_f_name : "")+`</ticketmaster_account_firstname>
<ticketmaster_account_lastname>`+(ticketmaster_ticket ? customer.ticketmaster_details.tm_l_name: "")+`</ticketmaster_account_lastname>
<associations>
<order_rows nodeType="order_row" virtualEntity="true">
<order_row>
<product_id xlink:href="https://www.kmttest.de/api/products/5013">`+event.id+`</product_id>
<product_attribute_id>`+ticket.allDetails.id+`</product_attribute_id>
<product_quantity>`+ticket.quantity+`</product_quantity>
<product_name>`+escapeHtml(event.name[0].value)+`</product_name>
<product_price>`+parseFloat(price).toFixed(6)+`</product_price>
<unit_price_tax_incl>`+parseFloat(price).toFixed(6)+`</unit_price_tax_incl>
<unit_price_tax_excl>`+parseFloat(price).toFixed(6)+`</unit_price_tax_excl>
<section>`+escapeHtml(ticket.name)+`</section>
<date>`+event.event_date+`</date>
<location>`+escapeHtml(event.location)+`</location>
<eticket>`+(ticket.allDetails.type == "1" ? "1" : "0") +`</eticket>
<commission></commission>
<payout></payout>
</order_row>
</order_rows>
</associations>
</order>
</prestashop>`
    );
}
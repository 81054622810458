import React, { useState, useEffect } from 'react'
import classes from './FAQ.module.css'
import { useTranslation } from 'react-i18next';
import rightIcon from '../../assets/images/icons/right-chevron.png'

import CustomSelect from '../UI/CustomSelect/CustomSelect';
import ContactUs from '../ContactUsBox/ContactUs';

import {LANG_GERMAN, LANG_ENGLISH} from '../../URLS';
import axios, {config} from '../../axiosBase';
import consoleLog from '../../log';

export default function FAQ() {


    const { t, i18n } = useTranslation();
    const [showContactBox, setShowContactBox] = useState(false);

    const [data, setData] = useState([]);
    const linkLangCode = localStorage.getItem("i18nextLng") === LANG_GERMAN ? 4 : 7;

    useEffect(() => {
        let faqList = [];

        axios.get('/faqcategories?output_format=JSON&a=get',config)
        .then(function(data){
            if(data.data.success){
                Object.values(data.data.data).map(function(items, index){
                    let faqItem = {};
                    faqItem["topic"] = items[linkLangCode].title;
                    faqItem["Ques"] = []
                    if(items[linkLangCode].questions){
                        Object.keys(items[linkLangCode].questions).map(function(questionKey, ind){
                            faqItem["Ques"].push({
                                "Q": items[linkLangCode].questions[questionKey][linkLangCode].title,
                                "A": items[linkLangCode].questions[questionKey][linkLangCode].content
                            })
                        })                        
                    }
                    faqList.push(faqItem)
                    if(index === Object.keys(data.data.data).length - 1){
                        setData(faqList)
                    }
                })
            }else{
                setData([])
            }
        })
        .catch(function(err){
            console.log(err)
        })      
    }, [])


    /*const data = [
        /*{
            "topic": t('FAQ_TOPIC1'),
            "Ques": [
                {
                    "Q": t('FAQ_Q1'),
                    "A": t('FAQ_A1')
                }
            ]
        },*/
        /*{
            "topic": t('FAQ_TOPIC2'),
            "Ques": [
                {
                    "Q": t('FAQ_Q2'),
                    "A": t('FAQ_A2')
                },
                {
                    "Q": t('FAQ_Q3'),
                    "A": t('FAQ_A3')
                },
                {
                    "Q": t('FAQ_Q4'),
                    "A": t('FAQ_A4')
                }



            ]
        },
        {
            "topic": t('FAQ_TOPIC3'),
            "Ques": [
                {
                    "Q": t('FAQ_Q5'),
                    "A": t('FAQ_A5')
                }
                ,
                {
                    "Q": t('FAQ_Q6'),
                    "A": t('FAQ_A6')
                },
                {
                    "Q": t('FAQ_Q7'),
                    "A": t('FAQ_A7')
                },
                {
                    "Q": t('FAQ_Q8'),
                    "A": t('FAQ_A8')
                },
                {
                    "Q": t('FAQ_Q9'),
                    "A": t('FAQ_A9')
                },
                {
                    "Q": t('FAQ_Q10'),
                    "A": t('FAQ_A10')
                }
            ]
        },
        {
            "topic": t('FAQ_TOPIC4'),
            "Ques": [
                {
                    "Q": t('FAQ_Q11'),
                    "A": t('FAQ_A11')
                },
                {
                    "Q": t('FAQ_Q12'),
                    "A": t('FAQ_A12')
                },
                {
                    "Q": t('FAQ_Q13'),
                    "A": t('FAQ_A13')
                },
                {
                    "Q": t('FAQ_Q14'),
                    "A": t('FAQ_A14')
                },
                /*{
                    "Q": t('FAQ_Q15'),
                    "A": t('FAQ_A15')
                },*
                {
                    "Q": t('FAQ_Q16'),
                    "A": t('FAQ_A16')
                },
                {
                    "Q": t('FAQ_Q17'),
                    "A": t('FAQ_A17')
                }
                ,
                {
                    "Q": t('FAQ_Q18'),
                    "A": t('FAQ_A18')
                },
                {
                    "Q": t('FAQ_Q19'),
                    "A": t('FAQ_A19')
                }


            ]
        },
        {
            "topic": t('FAQ_TOPIC5'),
            "Ques": [
                {
                    "Q": t('FAQ_Q20'),
                    "A": t('FAQ_A20')
                },
                {
                    "Q": t('FAQ_Q21'),
                    "A": t('FAQ_A21')
                }
            ]
        },
        {
            "topic": t('FAQ_TOPIC6'),
            "Ques": [
                {
                    "Q": t('FAQ_Q22'),
                    "A": t('FAQ_A22')
                },
                {
                    "Q": t('FAQ_Q23'),
                    "A": t('FAQ_A23')
                },
                {
                    "Q": t('FAQ_Q24'),
                    "A": t('FAQ_A24')
                },
                {
                    "Q": t('FAQ_Q25'),
                    "A": t('FAQ_A25')
                },
                {
                    "Q": t('FAQ_Q26'),
                    "A": t('FAQ_A26')
                },
                {
                    "Q": t('FAQ_Q27'),
                    "A": t('FAQ_A27')
                },
                {
                    "Q": t('FAQ_Q28'),
                    "A": t('FAQ_A28')
                },
                {
                    "Q": t('FAQ_Q29'),
                    "A": t('FAQ_A29')
                },
                {
                    "Q": t('FAQ_Q30'),
                    "A": t('FAQ_A30')
                },
                {
                    "Q": t('FAQ_Q31'),
                    "A": t('FAQ_A31')
                },
                {
                    "Q": t('FAQ_Q32'),
                    "A": t('FAQ_A32')
                },
                {
                    "Q": t('FAQ_Q33'),
                    "A": t('FAQ_A33')
                },
                {
                    "Q": t('FAQ_Q34'),
                    "A": t('FAQ_A34')
                },
                {
                    "Q": t('FAQ_Q36'),
                    "A": t('FAQ_A36')
                },
                {
                    "Q": t('FAQ_Q37'),
                    "A": t('FAQ_A37')
                },
            ]
        },
        {
            "topic": t('FAQ_TOPIC7'),
            "Ques": [
                {
                    "Q": t('FAQ_Q39'),
                    "A": t('FAQ_A39')
                },
                {
                    "Q": t('FAQ_Q40'),
                    "A": t('FAQ_A40')
                },
                {
                    "Q": t('FAQ_Q38'),
                    "A": t('FAQ_A38')
                },
                {
                    "Q": t('FAQ_Q1'),
                    "A": t('FAQ_A1')
                }
            ]
        },
        {
            "topic": t('FAQ_TOPIC8'),
            "Ques": [
                {
                    "Q": t('FAQ_Q41'),
                    "A": t('FAQ_A41')
                }
            ]
        }*/
    //]

    const topics = [
        { name: t("All"), value: ""},
        /*{ name: t('FAQ_TOPIC1'), value: t('FAQ_TOPIC1') },*/
        { name: t('FAQ_TOPIC2'), value: t('FAQ_TOPIC2') },
        { name: t('FAQ_TOPIC3'), value: t('FAQ_TOPIC3') },
        { name: t('FAQ_TOPIC4'), value: t('FAQ_TOPIC4') },
        { name: t('FAQ_TOPIC5'), value: t('FAQ_TOPIC5') },
        { name: t('FAQ_TOPIC6'), value: t('FAQ_TOPIC6') },
        { name: t('FAQ_TOPIC7'), value: t('FAQ_TOPIC7') },
        { name: t('FAQ_TOPIC8'), value: t('FAQ_TOPIC8') },
    ]

    const [selectedQues, setSelectedQues] = useState("")
    const [selectedTopic, setSelectedTopic] = useState("")
    const [listOpened, setListOpened] = useState(false);

    return (
        <div className={classes.FAQ}>

            <div>
                {/*<select className={classes.Filter} onChange={(e) => { setSelectedTopic(e.target.value) }}>
                    <option selected disabled hidden>Filter Category</option>
                    <option value="">All</option>
                    {topics.map((t, index) => {
                        return (
                            <option value={t}>{t}</option>
                        )
                    })}
                </select>*/}
                <CustomSelect
                    optionsList={topics}
                    changed={setSelectedTopic}
                    styling={{
                        container: window.screen.width > 370 ? { width: "200px", float: "right"} : { width: "200px", margin: "auto"},
                        defaultText: { background: "var(--blue)",
                                        padding: "10px 20px",
                                        color: "var(--black)",
                                        borderRadius: listOpened === 1 ? "20px 20px 0 0" : "30px" },
                        ul: {},
                        li: { background: "var(--white)",
                                color: "var(--black)",
                                border: "1px solid var(--blue)"}
                    }}
                    defaultText={ i18n.language === LANG_ENGLISH ? "All" : "Alle" }
                    listOpened={(item)=>setListOpened(item)}
                    listOpenIndex={1}
                />
            </div>

            {data.map((d, index) => {
                if (d.topic == selectedTopic || selectedTopic == "")
                    return (
                        <div id={"FAQ"+index}>
                            <p className={classes.Topic}>{d.topic}</p>
                            {
                                d.Ques.map((q, index1) => {
                                    return (
                                        <>
                                        { q.Q === "" ? null :
                                        <div>
                                            <p onClick={() => { selectedQues === q.Q ? setSelectedQues("") : setSelectedQues(q.Q) }}
                                                className={selectedQues === q.Q ? [classes.Q, classes.ToggleDown].join(" ") : [classes.Q, classes.ToggleUp].join(" ")}>
                                                <img src={rightIcon} />    {q.Q}
                                            </p>

                                            {q.Q === selectedQues &&
                                                <span>
                                                    {/*<label className={classes.Date} > <u>{t('POSTED')} : july 29, 2021</u>  <br /></label>*/}
                                                    <p className={classes.A} dangerouslySetInnerHTML={{__html: q.A }}></p>
                                                </span>
                                            }
                                        </div>
                                        }
                                        </>
                                    )
                                })
                            }
                        </div>
                    )
            })}
            {/*<div className={classes.AnswerNotFound}>
                <div>{t('cant_find_my_answer')} <span onClick={()=>setShowContactBox(true)}>{t('Contact_Us')}.</span></div>
            </div>*/}
            {
                showContactBox ?
                    <ContactUs
                        hideContact={()=>setShowContactBox(false)}
                        pageDetails="FAQ Contact - " // for internal use
                    /> : null
            }
        </div>
    )
}